// src/pages/TopicPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { motion, LazyMotion, domAnimation } from 'framer-motion';
import { Play, Clock, Calendar, ArrowLeft } from 'lucide-react';
import { fetchEpisodes } from '../services/episodeService';
import { podcastConfig } from '../config/podcast';
import LoadingSkeleton from '../components/LoadingSkeleton';

const TOPICS_CONFIG = {
  tesla: {
    title: "Tesla Coverage",
    description: "In-depth analysis of Tesla's electric vehicle technology, autonomous driving developments, and manufacturing innovations.",
    color: "from-blue-600/20",
    keyAreas: [
      "Electric Vehicles",
      "Full Self-Driving",
      "Gigafactory Updates",
      "Manufacturing Innovation",
      "Energy Products"
    ]
  },
  spacex: {
    title: "SpaceX Analysis",
    description: "Comprehensive coverage of SpaceX launches, Starship development, and the journey to make humanity multi-planetary.",
    color: "from-red-600/20",
    keyAreas: [
      "Starship Development",
      "Launch Coverage",
      "Mars Colonization",
      "Starlink Network",
      "Space Technology"
    ]
  },
  neuralink: {
    title: "Neuralink Updates",
    description: "Latest developments in brain-computer interface technology and Neuralink's progress in neural engineering.",
    color: "from-purple-600/20",
    keyAreas: [
      "Brain-Computer Interfaces",
      "Clinical Trials",
      "Neural Technology",
      "Medical Applications",
      "Research Updates"
    ]
  },
  "boring-company": {
    title: "The Boring Company",
    description: "Analysis of tunnel construction technology, Loop transportation systems, and underground infrastructure innovation.",
    color: "from-green-600/20",
    keyAreas: [
      "Tunnel Technology",
      "Loop System",
      "Urban Transit",
      "Construction Innovation",
      "Infrastructure Projects"
    ]
  },
  "x-platform": {
    title: "X Platform Coverage",
    description: "Examining the transformation of Twitter into X and its evolution as a global financial platform.",
    color: "from-yellow-600/20",
    keyAreas: [
      "Platform Updates",
      "Feature Development",
      "Financial Integration",
      "User Experience",
      "Content Moderation"
    ]
  }
};

const TopicHeader = ({ topic }) => (
  <div className="relative bg-[#2e3244] overflow-hidden">
    <div className="absolute inset-0">
      <div className={`absolute inset-0 bg-gradient-to-br ${topic.color} to-[#2e3244]/5`} />
    </div>
    
    <div className="relative max-w-7xl mx-auto px-6 py-24">
      <Link 
        to="/" 
        className="inline-flex items-center text-white/70 hover:text-white mb-8 transition-colors"
      >
        <ArrowLeft className="w-4 h-4 mr-2" />
        Back to Home
      </Link>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6">
          {topic.title}
        </h1>
        <p className="text-white/70 text-xl max-w-3xl mb-12">
          {topic.description}
        </p>

        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4">
          {topic.keyAreas.map((area, index) => (
            <div
              key={index}
              className="bg-[#1f2333]/50 backdrop-blur-sm rounded-lg px-4 py-3 text-white/70"
            >
              {area}
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  </div>
);

const EpisodeCard = ({ episode }) => (
  <motion.article
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-[#2e3244] rounded-xl p-6 hover:bg-[#353a4f] transition-colors"
  >
    <div className="flex gap-6">
      <div className="w-32 h-32 flex-shrink-0">
        <img
          src={episode.image}
          alt={episode.title}
          className="w-full h-full object-cover rounded-lg"
        />
      </div>
      
      <div className="flex-1">
        <div className="flex items-center gap-4 mb-2">
          <span className="text-[#3384ff] text-sm">Episode #{episode.episodeNumber}</span>
          <div className="flex items-center gap-2 text-white/50 text-sm">
            <Calendar className="w-4 h-4" />
            {new Date(episode.pubDate).toLocaleDateString()}
          </div>
          <div className="flex items-center gap-2 text-white/50 text-sm">
            <Clock className="w-4 h-4" />
            {episode.duration}
          </div>
        </div>
        
        <h2 className="text-xl font-bold text-white mb-2">{episode.title}</h2>
        <p className="text-white/70 mb-4 line-clamp-2">{episode.description}</p>
        
        <Link
          to={`/episode/${episode.episodeNumber}`}
          className="inline-flex items-center gap-2 text-[#3384ff] hover:text-[#3384ff]/80 transition-colors"
        >
          Listen Now <Play className="w-4 h-4" />
        </Link>
      </div>
    </div>
  </motion.article>
);

const TopicPage = () => {
  const { topicSlug } = useParams();
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [totalItems, setTotalItems] = useState(0);

  const topicConfig = TOPICS_CONFIG[topicSlug];

  useEffect(() => {
    const loadTopicEpisodes = async () => {
      try {
        setLoading(true);
        const result = await fetchEpisodes(podcastConfig, currentPage, topicSlug);
        setEpisodes(result.episodes);
        setHasMore(result.hasMore);
        setTotalItems(result.totalItems);
        setError(null);
      } catch (error) {
        setError('Failed to load episodes');
        setEpisodes([]);
      } finally {
        setLoading(false);
      }
    };

    loadTopicEpisodes();
  }, [topicSlug, currentPage]);

  if (!topicConfig) {
    return (
      <div className="min-h-screen bg-[#1f2333] flex items-center justify-center px-4">
        <div className="text-center">
          <h1 className="text-2xl font-bold text-white mb-4">Topic Not Found</h1>
          <p className="text-white/70 mb-8">The topic you're looking for doesn't exist.</p>
          <Link
            to="/"
            className="px-6 py-3 bg-[#3384ff] text-white rounded-xl hover:bg-[#3384ff]/90 transition-colors"
          >
            Return Home
          </Link>
        </div>
      </div>
    );
  }

  if (loading && currentPage === 1) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#1f2333] flex items-center justify-center px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Failed to load episodes</h2>
          <p className="text-white/70 mb-8">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-[#3384ff] text-white rounded-xl hover:bg-[#3384ff]/90 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <LazyMotion features={domAnimation}>
      <div className="min-h-screen bg-[#1f2333]">
        <Helmet>
          <title>{`${topicConfig.title} - The Elon Musk Podcast`}</title>
          <meta name="description" content={topicConfig.description} />
        </Helmet>

        <TopicHeader topic={topicConfig} />
        
        <div className="max-w-7xl mx-auto px-6 py-20">
          {episodes.length > 0 ? (
            <>
              <div className="space-y-6">
                {episodes.map(episode => (
                  <EpisodeCard key={episode.id} episode={episode} />
                ))}
              </div>

              {(hasMore || currentPage > 1) && (
                <div className="mt-12 flex justify-center gap-4">
                  <button
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    disabled={currentPage === 1}
                    className="px-6 py-3 bg-[#2e3244] text-white rounded-xl hover:bg-[#353a4f] transition-colors disabled:opacity-50 disabled:hover:bg-[#2e3244]"
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => setCurrentPage(p => p + 1)}
                    disabled={!hasMore}
                    className="px-6 py-3 bg-[#2e3244] text-white rounded-xl hover:bg-[#353a4f] transition-colors disabled:opacity-50 disabled:hover:bg-[#2e3244]"
                  >
                    Next
                  </button>
                </div>
              )}
            </>
          ) : (
            <div className="text-center py-20">
              <h2 className="text-2xl font-bold text-white mb-4">No Episodes Found</h2>
              <p className="text-white/70">
                We couldn't find any episodes related to this topic. Please check back later.
              </p>
            </div>
          )}
        </div>
      </div>
    </LazyMotion>
  );
};

export default TopicPage;