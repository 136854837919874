import React from 'react';
import { motion } from 'framer-motion';
import { Mic, Users, Headphones, Star, ExternalLink } from 'lucide-react';
import { Link } from 'react-router-dom';

const About = () => {
  const stats = [
    { label: 'Episodes Released', value: '850+', icon: Mic },
    { label: 'Monthly Listeners', value: '75K+', icon: Users },
    { label: 'Total Downloads', value: '3.2M+', icon: Headphones },
    { label: 'Average Rating', value: '4.8', icon: Star }
  ];

  const platforms = [
    {
      name: 'Apple Podcasts',
      url: 'https://podcasts.apple.com/us/podcast/elon-musk-podcast/id1480583664',
      icon: '/apple-podcast.svg'
    },
    {
      name: 'Spotify',
      url: 'https://open.spotify.com/show/49vTLjDIZufDkpxp2vPPtk',
      icon: '/spotify.svg'
    },
    {
      name: 'Amazon Music',
      url: 'https://music.amazon.com/podcasts/b10a41ac-e807-4b86-be3b-6cdc2e6c2932/elon-musk-podcast',
      icon: '/amazon-music.svg'
    },
    {
      name: 'YouTube',
      url: 'https://www.youtube.com/channel/UCVEV0_u1SMZBGJBaWctKZcg',
      icon: '/youtube.svg'
    }
  ];

  return (
    <div className="min-h-screen bg-zinc-950">
      {/* Hero Section */}
      <div className="relative bg-[#1f2333] py-24">
        <div className="max-w-7xl mx-auto px-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-5xl font-bold text-white mb-6">The Elon Musk Podcast</h1>
            <p className="text-xl text-zinc-400 max-w-2xl mx-auto">
              Your premier source for in-depth coverage of SpaceX, Tesla, and all of Elon Musk's innovative ventures.
              Bringing you the latest developments, expert analysis, and behind-the-scenes insights.
            </p>
          </motion.div>
        </div>
      </div>

      {/* Stats Section */}
      <div className="max-w-7xl mx-auto px-6 mt-20">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat, index) => {
            const Icon = stat.icon;
            return (
              <motion.div
                key={stat.label}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-zinc-900/50 rounded-xl p-6 text-center"
              >
                <div className="bg-orange-500/20 w-12 h-12 rounded-lg flex items-center justify-center mx-auto mb-4">
                  <Icon size={24} className="text-orange-400" />
                </div>
                <h3 className="text-3xl font-bold text-white mb-2">{stat.value}</h3>
                <p className="text-zinc-400">{stat.label}</p>
              </motion.div>
            );
          })}
        </div>
      </div>

      {/* Host Section */}
      <section className="max-w-7xl mx-auto px-6 py-24">
        <div className="grid md:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-3xl font-bold text-white mb-6">Meet Your Host</h2>
            <div className="prose prose-invert">
              <p className="text-lg text-zinc-400 mb-4">
                Wil Waldon has been at the forefront of covering Elon Musk's revolutionary companies for over four years. 
                As a passionate advocate for space exploration and sustainable technology, Wil brings unique insights and 
                firsthand experience to every episode.
              </p>
              <p className="text-lg text-zinc-400 mb-4">
                Having spent a year embedded at SpaceX's Starbase facility, Wil witnessed the birth of humanity's next 
                giant leap in space exploration. His dedicated coverage of Starbase's early days provides listeners with 
                unparalleled perspective on SpaceX's mission to make life multi-planetary.
              </p>
              <p className="text-lg text-zinc-400">
                As an enthusiastic follower of Elon's companies, particularly SpaceX, Wil combines deep technical 
                knowledge with genuine excitement for the future these innovations are creating.
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="space-y-6"
          >
            <img
              src="/headshot-wil.jpg"
              alt="Wil Waldon"
              className="w-full rounded-xl shadow-2xl"
            />
            <img
              src="/wil-starbase.jpg"
              alt="Wil at SpaceX Starbase"
              className="w-full rounded-xl shadow-2xl"
            />
          </motion.div>
        </div>
      </section>

      {/* Mission Section */}
      <section className="bg-gradient-to-br from-zinc-900 to-zinc-800">
        <div className="max-w-7xl mx-auto px-6 py-24">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold text-white mb-6">Our Mission</h2>
            <p className="text-zinc-400 text-lg leading-relaxed">
              We're dedicated to bringing you comprehensive coverage of the world's most innovative companies 
              and their visionary leader. Through in-depth analysis, expert interviews, and behind-the-scenes 
              insights, we help you understand the technologies and decisions shaping our future. Whether you're 
              a SpaceX enthusiast, Tesla owner, or simply curious about the future of technology, our podcast 
              delivers the insights you need.
            </p>
          </div>
        </div>
      </section>

      {/* Listen On Section */}
      <section className="max-w-7xl mx-auto px-6 py-24">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-white mb-4">Listen On Your Favorite Platform</h2>
          <p className="text-zinc-400">Subscribe and never miss an episode</p>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
          {platforms.map((platform) => (
            <a
              key={platform.name}
              href={platform.url}
              target="_blank"
              rel="noopener noreferrer"
              className="bg-zinc-900/50 rounded-xl p-6 text-center hover:bg-zinc-800/50 transition-colors group"
            >
              <img
                src={platform.icon}
                alt={platform.name}
                className="w-12 h-12 mx-auto mb-4"
              />
              <span className="text-white font-medium block mb-2">{platform.name}</span>
              <span className="text-orange-400 text-sm flex items-center justify-center gap-1 opacity-0 group-hover:opacity-100 transition-opacity">
                Listen Now <ExternalLink size={14} />
              </span>
            </a>
          ))}
        </div>
      </section>

      {/* Contact Section */}
      <section className="max-w-7xl mx-auto px-6 pb-24">
        <div className="bg-gradient-to-br from-orange-500/10 to-zinc-800/50 rounded-2xl p-12 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">Get in Touch</h2>
          <p className="text-zinc-400 mb-8">
            Have questions, suggestions, or want to be featured on the show?
          </p>
          <Link
            to="/contact"
            className="px-8 py-4 bg-orange-500 text-white rounded-xl hover:bg-orange-600 transition-colors inline-flex items-center gap-2"
          >
            Contact Us <ExternalLink size={14} />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default About;