// src/pages/Saved.jsx
import React, { useState } from 'react';
import { motion } from 'framer-motion';
import EpisodeCard from '../components/EpisodeCard';

const Saved = () => {
  const [filter, setFilter] = useState('all');
  const [savedEpisodes] = useState([
    {
      id: 1,
      title: "The Future of Space Travel",
      description: "Exploring the latest developments in space exploration and what it means for humanity's future among the stars.",
      pubDate: "2024-03-15",
      duration: "45:00",
      episodeNumber: 45,
      tags: ['SpaceX', 'Technology'],
      image: "/api/placeholder/400/300"
    },
    // Add more mock saved episodes as needed
  ]);

  return (
    <div className="max-w-7xl mx-auto px-6 py-12">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        <div className="flex justify-between items-center mb-12">
          <div>
            <h1 className="text-4xl font-bold text-white mb-2">Saved Episodes</h1>
            <p className="text-gray-400">Your personal collection of favorite episodes</p>
          </div>
          
          <div className="flex gap-2">
            <button
              onClick={() => setFilter('all')}
              className={`px-4 py-2 rounded-lg transition-all ${
                filter === 'all' 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
              }`}
            >
              All
            </button>
            <button
              onClick={() => setFilter('recent')}
              className={`px-4 py-2 rounded-lg transition-all ${
                filter === 'recent' 
                  ? 'bg-blue-600 text-white' 
                  : 'bg-gray-800 text-gray-400 hover:bg-gray-700'
              }`}
            >
              Recent
            </button>
          </div>
        </div>

        {savedEpisodes.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {savedEpisodes.map((episode) => (
              <motion.div
                key={episode.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                <EpisodeCard episode={episode} />
              </motion.div>
            ))}
          </div>
        ) : (
          <div className="text-center py-20">
            <div className="bg-gray-800/50 rounded-xl p-12 max-w-2xl mx-auto">
              <h3 className="text-2xl font-semibold text-white mb-4">No saved episodes yet</h3>
              <p className="text-gray-400 mb-8">
                Start building your collection by bookmarking your favorite episodes
              </p>
              <button className="px-6 py-3 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition-colors">
                Browse Episodes
              </button>
            </div>
          </div>
        )}
      </motion.div>
    </div>
  );
};

export default Saved;