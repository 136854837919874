import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Play, Clock, Calendar, Share2, ArrowLeft, ArrowRight } from 'lucide-react';
import AudioPlayer from '../components/AudioPlayer';
import { podcastConfig } from '../config/podcast';

// Utility functions
const stripHtml = (html) => {
  if (!html) return '';
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const formatDate = (dateString) => {
  try {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (error) {
    return 'Date unavailable';
  }
};

const parseDuration = (duration) => {
  if (!duration) return { display: '00:00', seconds: 0 };

  try {
    let seconds = 0;
    let display = '00:00';

    if (typeof duration === 'string' && duration.includes(':')) {
      const parts = duration.split(':').map(Number);
      if (parts.length === 2) {
        seconds = parts[0] * 60 + parts[1];
      } else if (parts.length === 3) {
        seconds = parts[0] * 3600 + parts[1] * 60 + parts[2];
      }
      display = duration;
    } else {
      seconds = parseInt(duration, 10);
      if (!isNaN(seconds)) {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        display = `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
      }
    }

    return { display, seconds };
  } catch (error) {
    console.error('Error parsing duration:', error);
    return { display: '00:00', seconds: 0 };
  }
};

const processEpisodeData = (episode, index, totalEpisodes) => {
  if (!episode) return null;

  try {
    const { display: duration, seconds: durationSeconds } = parseDuration(episode.duration);
    
    return {
      title: episode.title || 'Untitled Episode',
      description: episode.description || 'No description available',
      cleanDescription: stripHtml(episode.description || ''),
      pubDate: new Date(episode.pubDate || Date.now()),
      image: episode.thumbnail || podcastConfig.defaultImage,
      audioUrl: episode.enclosure?.link || '',
      duration: duration,
      durationSeconds: durationSeconds,
      episodeNumber: totalEpisodes - index,
      categories: generateCategories(episode.title, episode.description)
    };
  } catch (error) {
    console.error('Error processing episode data:', error);
    return null;
  }
};

const generateCategories = (title = '', description = '') => {
  const text = `${title} ${description}`.toLowerCase();
  const categories = new Set();

  const categoryKeywords = {
    'Technology': ['tech', 'technology', 'innovation'],
    'Business': ['business', 'company', 'startup'],
    'Space': ['space', 'rocket', 'mars'],
    'AI': ['ai', 'artificial intelligence', 'machine learning'],
    'Automotive': ['car', 'vehicle', 'automotive']
  };

  Object.entries(categoryKeywords).forEach(([category, keywords]) => {
    if (keywords.some(keyword => text.includes(keyword))) {
      categories.add(category);
    }
  });

  return Array.from(categories);
};

const EpisodeDetailPage = () => {
  const { episodeId } = useParams();
  const [episode, setEpisode] = useState(null);
  const [adjacentEpisodes, setAdjacentEpisodes] = useState({ prev: null, next: null });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchEpisodeData = async () => {
      if (!episodeId) {
        setError('Invalid episode ID');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const response = await fetch(
          `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(podcastConfig.feedUrl)}&api_key=${podcastConfig.rss2jsonApiKey}&count=999`
        );

        if (!response.ok) {
          throw new Error('Failed to fetch podcast feed');
        }

        const data = await response.json();

        if (data.status !== 'ok' || !Array.isArray(data.items)) {
          throw new Error('Invalid feed data');
        }

        const allEpisodes = data.items;
        const totalEpisodes = allEpisodes.length;

        // Find the episode with matching ID
        const targetEpisodeNumber = parseInt(episodeId);
        let currentIndex = -1;

        // Find matching episode
        for (let i = 0; i < allEpisodes.length; i++) {
          const episodeNumber = totalEpisodes - i;
          if (episodeNumber === targetEpisodeNumber) {
            currentIndex = i;
            break;
          }
        }

        if (currentIndex === -1) {
          throw new Error('Episode not found');
        }

        const currentEpisode = processEpisodeData(allEpisodes[currentIndex], currentIndex, totalEpisodes);

        if (!currentEpisode) {
          throw new Error('Failed to process episode data');
        }

        // Process adjacent episodes
        const prevEpisode = currentIndex < totalEpisodes - 1 ? {
          title: allEpisodes[currentIndex + 1].title,
          episodeNumber: totalEpisodes - (currentIndex + 1)
        } : null;

        const nextEpisode = currentIndex > 0 ? {
          title: allEpisodes[currentIndex - 1].title,
          episodeNumber: totalEpisodes - (currentIndex - 1)
        } : null;

        setEpisode(currentEpisode);
        setAdjacentEpisodes({ prev: prevEpisode, next: nextEpisode });

      } catch (error) {
        console.error('Error fetching episode:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEpisodeData();
  }, [episodeId]);

  if (loading) {
    return (
      <div className="min-h-screen bg-[#1f2333] flex items-center justify-center">
        <div className="animate-pulse space-y-4">
          <div className="h-4 bg-[#2e3244] rounded w-48"></div>
          <div className="h-4 bg-[#2e3244] rounded w-64"></div>
        </div>
      </div>
    );
  }

  if (error || !episode) {
    return (
      <div className="min-h-screen bg-[#1f2333] flex items-center justify-center px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Episode not found</h2>
          <p className="text-white/70 mb-8">{error}</p>
          <Link
            to="/episodes"
            className="px-6 py-3 bg-[#3384ff] text-white rounded-xl hover:bg-[#3384ff]/90 transition-colors"
          >
            Browse All Episodes
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-[#1f2333]">
      <Helmet>
        <title>{`${episode.title} - Podcast Title`}</title>
        <meta name="description" content={episode.cleanDescription} />
      </Helmet>

      <div className="max-w-4xl mx-auto px-4 py-12">
        {/* Episode Header */}
        <div className="space-y-6 mb-12">
          <div className="flex items-center gap-4 text-white/50 text-sm">
            <Link to="/episodes" className="hover:text-white transition-colors flex items-center gap-2">
              <ArrowLeft className="w-4 h-4" />
              Back to Episodes
            </Link>
            <div className="flex items-center gap-2">
              <Calendar className="w-4 h-4" />
              <span>{formatDate(episode.pubDate)}</span>
            </div>
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4" />
              <span>{episode.duration}</span>
            </div>
          </div>

          <h1 className="text-4xl font-bold text-white">{episode.title}</h1>

          {/* Categories */}
          {episode.categories?.length > 0 && (
            <div className="flex flex-wrap gap-2">
              {episode.categories.map((category, index) => (
                <span
                  key={index}
                  className="px-3 py-1 bg-[#2e3244] text-white/70 rounded-full text-sm"
                >
                  {category}
                </span>
              ))}
            </div>
          )}
        </div>

        {/* Audio Player */}
        <div className="mb-12">
          <AudioPlayer 
            src={episode.audioUrl}
            title={episode.title}
            duration={episode.durationSeconds}
          />
        </div>

        {/* Episode Description */}
        <div className="prose prose-invert max-w-none mb-12">
          <h2 className="text-2xl font-bold text-white mb-6">Show Notes</h2>
          <div 
            className="text-white/70"
            dangerouslySetInnerHTML={{ __html: episode.description }}
          />
        </div>

        {/* Navigation */}
        <div className="grid grid-cols-2 gap-6">
          {adjacentEpisodes.prev && (
            <Link
              to={`/episode/${adjacentEpisodes.prev.episodeNumber}`}
              className="bg-[#2e3244] rounded-xl p-6 hover:bg-[#353a4f] transition-colors group"
            >
              <div className="flex items-center gap-2 text-white/50 mb-2">
                <ArrowLeft className="w-4 h-4 group-hover:-translate-x-1 transition-transform" />
                <span>Previous Episode</span>
              </div>
              <p className="text-white font-medium line-clamp-2">{adjacentEpisodes.prev.title}</p>
            </Link>
          )}
          
          {adjacentEpisodes.next && (
            <Link
              to={`/episode/${adjacentEpisodes.next.episodeNumber}`}
              className="bg-[#2e3244] rounded-xl p-6 hover:bg-[#353a4f] transition-colors group ml-auto"
            >
              <div className="flex items-center justify-end gap-2 text-white/50 mb-2">
                <span>Next Episode</span>
                <ArrowRight className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
              </div>
              <p className="text-white font-medium line-clamp-2 text-right">{adjacentEpisodes.next.title}</p>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default EpisodeDetailPage;