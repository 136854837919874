// src/utils/textUtils.js
export const stripHtmlTags = (html) => {
  if (!html) return '';
  
  // Create a temporary element
  const temp = document.createElement('div');
  temp.innerHTML = html;
  
  // Get text content
  const text = temp.textContent || temp.innerText;
  
  // Clean up any extra whitespace
  return text.trim().replace(/\s+/g, ' ');
};

// Optional: Add a function to truncate text if needed
export const truncateText = (text, maxLength) => {
  if (!text || text.length <= maxLength) return text;
  return text.slice(0, maxLength) + '...';
};