import React from 'react';
import { motion } from 'framer-motion';
import { Cookie } from 'lucide-react';

const CookiesPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="max-w-4xl mx-auto px-6 py-12"
    >
      <div className="flex items-center gap-4 mb-8">
        <div className="w-12 h-12 rounded-xl bg-[#3384ff]/10 flex items-center justify-center">
          <Cookie className="w-6 h-6 text-[#3384ff]" />
        </div>
        <h1 className="text-3xl font-bold text-white">Cookie Policy</h1>
      </div>

      <div className="prose prose-invert max-w-none">
        <p className="text-white/70">Last updated: December 12, 2024</p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">1. What Are Cookies</h2>
        <p className="text-white/70 mb-6">
          Cookies are small text files that are placed on your computer or mobile device when you visit our website. They are widely used to make websites work more efficiently and provide valuable information to website owners.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">2. Types of Cookies We Use</h2>
        
        <h3 className="text-lg font-semibold text-white mt-6 mb-3">2.1 Essential Cookies</h3>
        <p className="text-white/70 mb-6">
          These cookies are necessary for the website to function properly. They enable basic functions like page navigation and access to secure areas of the website.
        </p>

        <h3 className="text-lg font-semibold text-white mt-6 mb-3">2.2 Performance Cookies</h3>
        <p className="text-white/70 mb-6">
          These cookies help us understand how visitors interact with our website by collecting and reporting information anonymously.
        </p>

        <h3 className="text-lg font-semibold text-white mt-6 mb-3">2.3 Functional Cookies</h3>
        <p className="text-white/70 mb-6">
          These cookies enable the website to provide enhanced functionality and personalization based on your preferences and choices.
        </p>

        <h3 className="text-lg font-semibold text-white mt-6 mb-3">2.4 Targeting Cookies</h3>
        <p className="text-white/70 mb-6">
          These cookies may be set through our site by our advertising partners to build a profile of your interests and show you relevant advertisements on other sites.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">3. Cookie Management</h2>
        <p className="text-white/70 mb-6">
          You can control and manage cookies in various ways:
        </p>
        <ul className="list-disc pl-6 text-white/70 mb-6">
          <li>Browser settings to block/delete cookies</li>
          <li>Cookie preference center on our website</li>
          <li>Third-party opt-out tools</li>
        </ul>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">4. Specific Cookies We Use</h2>
        <div className="overflow-x-auto mb-6">
          <table className="w-full text-white/70 border-collapse">
            <thead>
              <tr className="border-b border-white/10">
                <th className="text-left py-2 px-4">Cookie Name</th>
                <th className="text-left py-2 px-4">Purpose</th>
                <th className="text-left py-2 px-4">Duration</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-b border-white/10">
                <td className="py-2 px-4">_ga</td>
                <td className="py-2 px-4">Google Analytics tracking</td>
                <td className="py-2 px-4">2 years</td>
              </tr>
              <tr className="border-b border-white/10">
                <td className="py-2 px-4">session_id</td>
                <td className="py-2 px-4">User session management</td>
                <td className="py-2 px-4">Session</td>
              </tr>
              <tr className="border-b border-white/10">
                <td className="py-2 px-4">preferences</td>
                <td className="py-2 px-4">User preferences</td>
                <td className="py-2 px-4">1 year</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">5. Changes to This Policy</h2>
        <p className="text-white/70 mb-6">
          We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "Last updated" date.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">6. Contact Us</h2>
        <p className="text-white/70 mb-6">
          If you have any questions about our Cookie Policy, please contact us at:
          <br />
         Email: privacy@elonmuskpodcast.com
          <br />
          Address: 100 Bull Street, Suite 200, Savannah, GA 34101
        </p>

        <div className="bg-[#2e3244] rounded-xl p-6 mt-8">
          <h2 className="text-xl font-semibold text-white mb-4">Your Cookie Preferences</h2>
          <p className="text-white/70 mb-6">
            You can manage your cookie preferences at any time. Please note that certain cookies are essential for the website's functionality and cannot be disabled.
          </p>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-white font-medium">Essential Cookies</h3>
                <p className="text-sm text-white/70">Required for basic site functionality</p>
              </div>
              <div className="w-12 h-6 bg-[#3384ff] rounded-full relative cursor-not-allowed">
                <div className="absolute right-1 top-1 w-4 h-4 bg-white rounded-full"></div>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-white font-medium">Analytics Cookies</h3>
                <p className="text-sm text-white/70">Help us improve our website</p>
              </div>
              <button className="w-12 h-6 bg-[#3384ff] rounded-full relative">
                <div className="absolute right-1 top-1 w-4 h-4 bg-white rounded-full"></div>
              </button>
            </div>
            <div className="flex items-center justify-between">
              <div>
                <h3 className="text-white font-medium">Marketing Cookies</h3>
                <p className="text-sm text-white/70">Used for targeted advertising</p>
              </div>
              <button className="w-12 h-6 bg-gray-700 rounded-full relative">
                <div className="absolute left-1 top-1 w-4 h-4 bg-white rounded-full"></div>
              </button>
            </div>
          </div>
          <button className="mt-6 px-6 py-2 bg-[#3384ff] text-white rounded-lg hover:bg-[#3384ff]/90 transition-colors">
            Save Preferences
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default CookiesPage;