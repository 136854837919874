// src/components/LoadingSkeleton.jsx
import React from 'react';

const LoadingSkeleton = () => (
  <div className="min-h-screen bg-[#1f2333]">
    {/* Hero Section Skeleton */}
    <div className="relative bg-[#2e3244] overflow-hidden">
      <div className="relative max-w-7xl mx-auto px-6 py-12">
        <div className="grid lg:grid-cols-2 gap-8 items-center">
          <div className="space-y-6">
            <div className="w-32 h-6 bg-[#3384ff]/10 rounded-full animate-pulse" />
            <div className="space-y-4">
              <div className="h-12 bg-white/5 rounded-xl animate-pulse" />
              <div className="h-20 bg-white/5 rounded-xl animate-pulse" />
            </div>
            <div className="h-40 bg-white/5 rounded-xl animate-pulse" />
          </div>
          <div className="hidden lg:block">
            <div className="aspect-video bg-white/5 rounded-xl animate-pulse" />
          </div>
        </div>
      </div>
    </div>

    {/* Content Skeleton */}
    <div className="max-w-7xl mx-auto px-6 py-12">
      <div className="space-y-6">
        {[1, 2, 3].map((i) => (
          <div key={i} className="bg-[#2e3244] rounded-xl p-6">
            <div className="flex gap-6">
              <div className="w-40 h-40 bg-white/5 rounded-lg animate-pulse" />
              <div className="flex-1 space-y-4">
                <div className="h-6 bg-white/5 rounded animate-pulse w-3/4" />
                <div className="h-4 bg-white/5 rounded animate-pulse w-1/2" />
                <div className="h-20 bg-white/5 rounded animate-pulse" />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export default LoadingSkeleton;