import React from 'react';
import { motion } from 'framer-motion';
import { Shield } from 'lucide-react';

const TermsPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="max-w-4xl mx-auto px-6 py-12"
    >
      <div className="flex items-center gap-4 mb-8">
        <div className="w-12 h-12 rounded-xl bg-[#3384ff]/10 flex items-center justify-center">
          <Shield className="w-6 h-6 text-[#3384ff]" />
        </div>
        <h1 className="text-3xl font-bold text-white">Terms of Service</h1>
      </div>

      <div className="prose prose-invert max-w-none">
        <p className="text-white/70">Last updated: December 12, 2024</p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">1. Agreement to Terms</h2>
        <p className="text-white/70 mb-6">
          By accessing and using the Elon Musk Podcast website and services, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">2. Use of Services</h2>
        <p className="text-white/70 mb-6">
          Our services are provided for personal, non-commercial use. You agree not to modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information obtained from our services without our explicit written permission.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">3. User Accounts</h2>
        <p className="text-white/70 mb-6">
          When you create an account with us, you guarantee that the information you provide is accurate and complete. You are responsible for maintaining the confidentiality of your account and password.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">4. Intellectual Property</h2>
        <p className="text-white/70 mb-6">
          All content on this website, including but not limited to text, graphics, logos, audio clips, and software, is the property of Elon Musk Podcast and is protected by copyright and other intellectual property laws.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">5. User Content</h2>
        <p className="text-white/70 mb-6">
          By submitting content to our platform, you grant us a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, and distribute your content across our services.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">6. Limitation of Liability</h2>
        <p className="text-white/70 mb-6">
          We shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our services.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">7. Changes to Terms</h2>
        <p className="text-white/70 mb-6">
          We reserve the right to modify these terms at any time. We will notify users of any material changes via email or through our website.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">8. Contact Information</h2>
        <p className="text-white/70 mb-6">
          For questions about these Terms of Service, please contact us at:
          <br />
          Email: legal@elonmuskpodcast.com
          <br />
          Address: 100 Bull Street, Suite 200, Savannah, GA 34101
        </p>
      </div>
    </motion.div>
  );
};

export default TermsPage;