import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { motion, LazyMotion, domAnimation } from 'framer-motion';
import { 
  Mic, 
  Play, 
  Clock, 
  Users, 
  Headphones, 
  Radio, 
  Star, 
  Calendar 
} from 'lucide-react';
import CountUp from 'react-countup';
import { podcastConfig } from '../config/podcast';
import LoadingSkeleton from '../components/LoadingSkeleton';
import NewsletterSubscribe from '../components/NewsletterSubscribe';
import NewsMarquee from '../components/NewsMarquee/NewsMarquee';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-WT1E217W2E');

// Lazy load components
const AudioPlayer = lazy(() => import('../components/AudioPlayer'));
const FeaturedEpisodes = lazy(() => import('../components/FeaturedEpisodes'));

// Utility Functions
const stripHtml = html => {
  if (!html) return '';
  const tmp = document.createElement('DIV');
  tmp.innerHTML = html;
  return tmp.textContent || tmp.innerText || '';
};

const formatTime = time => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

// Constants
const SITE_CONFIG = {
  title: 'The Elon Musk Podcast',
  description: 'Your premier source for in-depth analysis and discussions about Tesla, SpaceX, Neuralink, and the visionary behind them. Join us as we explore the companies and innovations shaping humanity\'s future.',
  baseUrl: 'https://elonmuskpodcast.com'
};

// SEO Enhancement - Schema.org structured data
const getStructuredData = (latestEpisode, totalEpisodes) => ({
  "@context": "https://schema.org",
  "@type": "PodcastSeries",
  "name": SITE_CONFIG.title,
  "description": SITE_CONFIG.description,
  "url": SITE_CONFIG.baseUrl,
  "author": {
    "@type": "Person",
    "name": "Warren Redlich",
    "url": `${SITE_CONFIG.baseUrl}/about`
  },
  "numberOfEpisodes": totalEpisodes,
  "image": `${SITE_CONFIG.baseUrl}/podcast-cover.jpg`,
  "publication": [{
    "@type": "BroadcastEvent",
    "isLiveBroadcast": false,
    "startDate": latestEpisode?.pubDate,
    "name": latestEpisode?.title
  }]
});

// Components
const HeroSection = React.memo(({ episode }) => {
  if (!episode) return null;

  const cleanDescription = stripHtml(episode.description);

  return (
    <div className="relative bg-[#2e3244] overflow-hidden">
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-gradient-to-br from-[#3384ff]/5 to-[#2e3244]/5" />
      </div>
      
      <div className="relative max-w-7xl mx-auto px-6 py-24">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <span className="inline-flex items-center px-3 py-1.5 rounded-full text-sm font-medium bg-[#3384ff]/10 text-[#3384ff] mb-6">
              Latest Episode #{episode.episodeNumber}
            </span>
            
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-white mb-6 leading-tight">
              {episode.title}
            </h1>
            
            <p className="text-white/70 text-lg mb-8 leading-relaxed max-w-xl">
              {cleanDescription.slice(0, 200)}...
            </p>
            
            <div className="space-y-6">
              <Suspense fallback={<div className="h-24 bg-[#1f2333] rounded-xl animate-pulse" />}>
                <AudioPlayer 
                  src={episode.audioUrl} 
                  title={episode.title}
                  duration={episode.duration}
                />
              </Suspense>
              
              <div className="flex flex-wrap items-center gap-6">
                <Link 
                  to={`/episode/${episode.episodeNumber}`}
                  className="group px-8 py-4 bg-[#3384ff] text-white rounded-xl hover:bg-[#3384ff]/90 transition-all duration-300 flex items-center gap-2"
                >
                  <span>Listen Now</span>
                  <Play className="w-4 h-4 group-hover:translate-x-1 transition-transform" />
                </Link>
                
                <div className="flex items-center gap-2 text-white/50">
                  <Clock className="w-4 h-4" />
                  <span>{formatTime(episode.duration)}</span>
                </div>
              </div>
            </div>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="relative hidden lg:block"
          >
            <div className="aspect-square rounded-2xl overflow-hidden bg-[#1f2333] shadow-2xl">
              <img
                src={episode.image}
                alt={episode.title}
                className="w-full h-full object-cover"
                loading="eager"
                width="600"
                height="600"
              />
              <div className="absolute inset-0 bg-gradient-to-br from-transparent to-[#1f2333]/50" />
            </div>
            
            <div className="absolute -bottom-6 -left-6 p-6 bg-[#2e3244]/90 backdrop-blur-sm rounded-xl border border-white/10 shadow-xl">
              <div className="flex items-center gap-4">
                <div className="w-12 h-12 rounded-full bg-[#3384ff] flex items-center justify-center">
                  <Mic className="w-6 h-6 text-white" />
                </div>
                <div>
                  <p className="text-white font-medium">Latest Episode</p>
                  <p className="text-white/50 text-sm">Join the conversation</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
});

// Add this component definition before the StatisticsSection component
const StatCard = React.memo(({ title, value, icon: Icon, suffix = '', prefix = '' }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    whileInView={{ opacity: 1, y: 0 }}
    viewport={{ once: true }}
    transition={{ duration: 0.5 }}
    className="bg-[#2e3244] rounded-xl p-6 hover:bg-[#353a4f] transition-colors"
  >
    <div className="flex items-center gap-4">
      <div className="w-12 h-12 rounded-lg bg-[#3384ff]/10 flex items-center justify-center">
        <Icon className="w-6 h-6 text-[#3384ff]" />
      </div>
      <div>
        <div className="text-2xl font-bold text-white mb-1">
          {prefix}
          <CountUp end={value} duration={2} separator="," />
          {suffix}
        </div>
        <div className="text-white/50 text-sm">{title}</div>
      </div>
    </div>
  </motion.div>
));

StatCard.displayName = 'StatCard';

const StatisticsSection = ({ totalEpisodes }) => {
  const stats = [
    { title: 'Episodes Released', value: 850, icon: Radio, suffix: '+' },
    { title: 'Monthly Listeners', value: 75000, icon: Users, suffix: '+' },
    { title: 'Total Downloads', value: 3200000, icon: Headphones, suffix: '+' },
    { title: 'Average Rating', value: 4.8, icon: Star }
  ];

  return (
    <section className="py-20">
      <div className="max-w-7xl mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {stats.map((stat) => (
            <StatCard key={stat.title} {...stat} />
          ))}
        </div>
      </div>
    </section>
  );
};

const TopicSection = () => (
  <section className="py-20 bg-[#2a2f42]">
    <div className="max-w-7xl mx-auto px-6">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
      >
        <h2 className="text-3xl font-bold text-white mb-8">Featured Topics</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          <article className="bg-[#2e3244] rounded-xl p-8 hover:bg-[#353a4f] transition-colors">
            <h3 className="text-xl font-bold text-white mb-4">Tesla Innovation</h3>
            <p className="text-white/70 mb-6">
              Comprehensive analysis of Tesla's electric vehicle technology, manufacturing processes, and autonomous driving advancements. From Gigafactory developments to FSD updates.
            </p>
            <Link to="/topics/tesla" className="text-[#3384ff] hover:text-[#3384ff]/80 transition-colors inline-flex items-center gap-2">
              Explore Tesla Coverage <Play className="w-4 h-4" />
            </Link>
          </article>

          <article className="bg-[#2e3244] rounded-xl p-8 hover:bg-[#353a4f] transition-colors">
            <h3 className="text-xl font-bold text-white mb-4">SpaceX Missions</h3>
            <p className="text-white/70 mb-6">
              In-depth coverage of SpaceX launches, Starship development, and Mars colonization plans. Following humanity's journey to becoming a multi-planetary species.
            </p>
            <Link to="/topics/spacex" className="text-[#3384ff] hover:text-[#3384ff]/80 transition-colors inline-flex items-center gap-2">
              Explore SpaceX Coverage <Play className="w-4 h-4" />
            </Link>
          </article>

          <article className="bg-[#2e3244] rounded-xl p-8 hover:bg-[#353a4f] transition-colors">
            <h3 className="text-xl font-bold text-white mb-4">Neuralink Progress</h3>
            <p className="text-white/70 mb-6">
              Latest developments in brain-computer interface technology, clinical trials, and Neuralink's advancements in neural engineering and medical applications.
            </p>
            <Link to="/topics/neuralink" className="text-[#3384ff] hover:text-[#3384ff]/80 transition-colors inline-flex items-center gap-2">
              Explore Neuralink Coverage <Play className="w-4 h-4" />
            </Link>
          </article>

          <article className="bg-[#2e3244] rounded-xl p-8 hover:bg-[#353a4f] transition-colors">
            <h3 className="text-xl font-bold text-white mb-4">The Boring Company</h3>
            <p className="text-white/70 mb-6">
              Analysis of tunnel construction technology, Loop transportation systems, and urban transit solutions. Tracking the revolution in underground infrastructure.
            </p>
            <Link to="/topics/boring-company" className="text-[#3384ff] hover:text-[#3384ff]/80 transition-colors inline-flex items-center gap-2">
              Explore Boring Company Coverage <Play className="w-4 h-4" />
            </Link>
          </article>

          <article className="bg-[#2e3244] rounded-xl p-8 hover:bg-[#353a4f] transition-colors">
            <h3 className="text-xl font-bold text-white mb-4">X (Twitter)</h3>
            <p className="text-white/70 mb-6">
              Examining the transformation of Twitter into X, covering platform developments, feature updates, and the vision for a global financial platform.
            </p>
            <Link to="/topics/x-platform" className="text-[#3384ff] hover:text-[#3384ff]/80 transition-colors inline-flex items-center gap-2">
              Explore X Coverage <Play className="w-4 h-4" />
            </Link>
          </article>

          <article className="bg-[#2e3244] rounded-xl p-8 hover:bg-[#353a4f] transition-colors">
            <h3 className="text-xl font-bold text-white mb-4">Technology Impact</h3>
            <p className="text-white/70 mb-6">
              Broader discussions on how these innovations are shaping our future, from sustainable energy to artificial intelligence and their societal implications.
            </p>
            <Link to="/topics/tech-impact" className="text-[#3384ff] hover:text-[#3384ff]/80 transition-colors inline-flex items-center gap-2">
              Explore Impact Analysis <Play className="w-4 h-4" />
            </Link>
          </article>
        </div>
      </motion.div>
    </div>
  </section>
);

const NewsletterSection = () => (
  <section className="py-20">
    <div className="max-w-4xl mx-auto px-6">
      <motion.div 
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="bg-[#2e3244] rounded-2xl p-12 text-center"
      >
        <h2 className="text-3xl font-bold text-white mb-4">Never Miss an Episode</h2>
        <p className="text-white/70 mb-8">Subscribe to our newsletter for the latest episodes and updates</p>
        <NewsletterSubscribe className="max-w-xl mx-auto" />
      </motion.div>
    </div>
  </section>
);

// Data fetching function
const fetchPodcastFeed = async (config) => {
  try {
    // First, get the total count of episodes
    const feedResponse = await fetch(config.feedUrl);
    const feedText = await feedResponse.text();
    const parser = new DOMParser();
    const feedDoc = parser.parseFromString(feedText, "text/xml");
    const totalEpisodes = feedDoc.getElementsByTagName('item').length;

    const response = await fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(config.feedUrl)}&api_key=${config.rss2jsonApiKey}&count=${config.episodesPerPage}`
    );
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error('Failed to fetch podcast feed');
    }

    return data.items.map((item, index) => ({
      id: index + 1,
      title: item.title,
      description: item.description,
      pubDate: item.pubDate,
      image: item.thumbnail || item.enclosure?.thumbnail || config.defaultImage,
      audioUrl: item.enclosure?.link,
      duration: item.duration || "00:00",
      episodeNumber: totalEpisodes - index,
      link: item.link
    }));
  } catch (error) {
    console.error('Error fetching podcast feed:', error);
    throw error;
  }
};

// Main component
function Home() {
  const [episodes, setEpisodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    const loadEpisodes = async () => {
      try {
        setLoading(true);
        const episodesData = await fetchPodcastFeed(podcastConfig);
        setEpisodes(episodesData);
        setError(null);
      } catch (error) {
        console.error('Failed to load episodes:', error);
        setError(error.message);
        setEpisodes([]);
      } finally {
        setLoading(false);
      }
    };

    loadEpisodes();
  }, []);

  if (loading) {
    return <LoadingSkeleton />;
  }

  if (error) {
    return (
      <div className="min-h-screen bg-[#1f2333] flex items-center justify-center px-4">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-white mb-4">Failed to load episodes</h2>
          <p className="text-white/70 mb-8">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-6 py-3 bg-[#3384ff] text-white rounded-xl hover:bg-[#3384ff]/90 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <LazyMotion features={domAnimation}>
      <div className="min-h-screen bg-[#1f2333] w-full">
        <NewsMarquee />
        <Helmet>
          <title>{SITE_CONFIG.title}</title>
          <meta name="description" content={SITE_CONFIG.description} />
          <link rel="canonical" href={`${SITE_CONFIG.baseUrl}${location.pathname}`} />
          
          {/* Open Graph */}
          <meta property="og:title" content={SITE_CONFIG.title} />
          <meta property="og:description" content={SITE_CONFIG.description} />
          <meta property="og:image" content={`${SITE_CONFIG.baseUrl}/og-image.jpg`} />
          <meta property="og:url" content={`${SITE_CONFIG.baseUrl}${location.pathname}`} />
          <meta property="og:type" content="website" />
          
          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@elonmuskpodcast" />
          
          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify(getStructuredData(episodes[0], episodes.length))}
          </script>
        </Helmet>

        <Suspense fallback={<LoadingSkeleton />}>
          <HeroSection episode={episodes[0]} />
          <div className="max-w-7xl mx-auto">
            <StatisticsSection totalEpisodes={episodes.length} />
            <TopicSection />
            <FeaturedEpisodes episodes={episodes} />
            
            {/* Expert Analysis Section */}
            <section className="py-20 bg-[#2a2f42]">
              <div className="max-w-7xl mx-auto px-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                >
                  <h2 className="text-3xl font-bold text-white mb-12">Expert Analysis & Commentary</h2>
                  <div className="grid md:grid-cols-2 gap-8">
                    <div className="bg-[#2e3244] rounded-xl p-8">
                      <h3 className="text-xl font-bold text-white mb-4">Industry-Leading Insights</h3>
                      <p className="text-white/70 mb-6">
                        Join us for deep dives into Tesla's manufacturing innovations, SpaceX's aerospace breakthroughs, 
                        and Neuralink's revolutionary technology. Our expert analysis helps you understand the future of 
                        transportation, space exploration, and neural interfaces.
                      </p>
                      <ul className="space-y-4 text-white/70">
                        <li className="flex items-center gap-2">
                          <Star className="w-4 h-4 text-[#3384ff]" /> Weekly technical analysis
                        </li>
                        <li className="flex items-center gap-2">
                          <Star className="w-4 h-4 text-[#3384ff]" /> Expert interviews
                        </li>
                        <li className="flex items-center gap-2">
                          <Star className="w-4 h-4 text-[#3384ff]" /> Company development updates
                        </li>
                      </ul>
                    </div>
                    
                    <div className="bg-[#2e3244] rounded-xl p-8">
                      <div className="h-full flex flex-col justify-between">
                        <div className="mb-8">
                          <h3 className="text-xl font-bold text-white mb-4">Latest Coverage</h3>
                          <ul className="space-y-4">
                            {episodes.slice(0, 3).map(episode => (
                              <li key={episode.id} className="text-white/70">
                                <Link 
                                  to={`/episode/${episode.episodeNumber}`}
                                  className="hover:text-white transition-colors"
                                >
                                  {episode.title}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <Link
                          to="/episodes"
                          className="inline-flex items-center gap-2 text-[#3384ff] hover:text-[#3384ff]/80 transition-colors"
                        >
                          View All Episodes <Play className="w-4 h-4" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </section>

            {/* Platform Links */}
            <section className="py-20">
              <div className="max-w-7xl mx-auto px-6">
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                >
                  <h2 className="text-3xl font-bold text-white mb-12">Listen On Your Favorite Platform</h2>
                  <div className="grid grid-cols-2 md:grid-cols-4 gap-6">
                    {[
                      { name: 'Apple Podcasts', url: '#' },
                      { name: 'Spotify', url: '#' },
                      { name: 'Google Podcasts', url: '#' },
                      { name: 'RSS Feed', url: '#' }
                    ].map(platform => (
                      <a
                        key={platform.name}
                        href={platform.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="bg-[#2e3244] rounded-xl p-6 text-center hover:bg-[#353a4f] transition-colors"
                      >
                        <h3 className="text-white font-medium">{platform.name}</h3>
                      </a>
                    ))}
                  </div>
                </motion.div>
              </div>
            </section>

            <NewsletterSection />

            {/* SEO Content Section */}
            <section className="py-20 bg-[#2a2f42]">
              <div className="max-w-7xl mx-auto px-6">
                <div className="prose prose-invert max-w-none">
                  <h2 className="text-3xl font-bold text-white mb-8">About The Elon Musk Podcast</h2>
                  <div className="text-white/70 space-y-6">
                    <p>
                      The Elon Musk Podcast is your premier source for in-depth analysis and discussions 
                      about Tesla, SpaceX, Neuralink, and the visionary behind these companies. With over 
                      800 episodes, we bring you comprehensive coverage of the technological innovations 
                      shaping our future.
                    </p>
                    <p>
                      Each episode features detailed analysis of company developments, technological 
                      breakthroughs, and market impacts. From Tesla's advancement in electric vehicles 
                      and autonomous driving to SpaceX's revolutionary space technology and Neuralink's 
                      neural interface innovations, we cover it all.
                    </p>
                    <p>
                      Join our community of technology enthusiasts, investors, and innovators as we 
                      explore the companies and technologies that are shaping humanity's future. Subscribe 
                      to never miss an episode and stay informed about the latest developments in the 
                      world of technology and innovation.
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </Suspense>
      </div>
    </LazyMotion>
  );
}

export default React.memo(Home);