import React from 'react';
import { motion } from 'framer-motion';
import { Lock } from 'lucide-react';

const PrivacyPage = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
      className="max-w-4xl mx-auto px-6 py-12"
    >
      <div className="flex items-center gap-4 mb-8">
        <div className="w-12 h-12 rounded-xl bg-[#3384ff]/10 flex items-center justify-center">
          <Lock className="w-6 h-6 text-[#3384ff]" />
        </div>
        <h1 className="text-3xl font-bold text-white">Privacy Policy</h1>
      </div>

      <div className="prose prose-invert max-w-none">
        <p className="text-white/70">Last updated: December 12, 2024</p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">1. Information We Collect</h2>
        <p className="text-white/70 mb-6">
          We collect information you provide directly to us, including:
        </p>
        <ul className="list-disc pl-6 text-white/70 mb-6">
          <li>Name and contact information</li>
          <li>Account credentials</li>
          <li>Payment information</li>
          <li>Communication preferences</li>
          <li>Usage data and analytics</li>
        </ul>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">2. How We Use Your Information</h2>
        <p className="text-white/70 mb-6">
          We use the collected information to:
        </p>
        <ul className="list-disc pl-6 text-white/70 mb-6">
          <li>Provide and maintain our services</li>
          <li>Process your transactions</li>
          <li>Send you updates and marketing communications</li>
          <li>Improve our services</li>
          <li>Comply with legal obligations</li>
        </ul>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">3. Information Sharing</h2>
        <p className="text-white/70 mb-6">
          We do not sell your personal information. We may share your information with:
        </p>
        <ul className="list-disc pl-6 text-white/70 mb-6">
          <li>Service providers and business partners</li>
          <li>Legal authorities when required by law</li>
          <li>Third parties with your consent</li>
        </ul>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">4. Data Security</h2>
        <p className="text-white/70 mb-6">
          We implement appropriate technical and organizational measures to protect your personal information. However, no security system is impenetrable, and we cannot guarantee the security of our systems 100%.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">5. Your Rights</h2>
        <p className="text-white/70 mb-6">
          You have the right to:
        </p>
        <ul className="list-disc pl-6 text-white/70 mb-6">
          <li>Access your personal information</li>
          <li>Correct inaccurate data</li>
          <li>Request deletion of your data</li>
          <li>Object to processing of your data</li>
          <li>Export your data</li>
        </ul>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">6. Children's Privacy</h2>
        <p className="text-white/70 mb-6">
          Our services are not directed to children under 13. We do not knowingly collect personal information from children under 13.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">7. Changes to This Policy</h2>
        <p className="text-white/70 mb-6">
          We may update this privacy policy from time to time. We will notify you of any changes by posting the new policy on this page and updating the "Last updated" date.
        </p>

        <h2 className="text-xl font-semibold text-white mt-8 mb-4">8. Contact Us</h2>
        <p className="text-white/70 mb-6">
          If you have any questions about this Privacy Policy, please contact us at:
          <br />
          Email: privacy@elonmuskpodcast.com
          <br />
          Address: 100 Bull Street, Suite 200, Savannah, GA 34101
        </p>
      </div>
    </motion.div>
  );
};

export default PrivacyPage;