import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { motion } from 'framer-motion';
import { Search, Filter, Calendar, ArrowRight } from 'lucide-react';

const categories = [
  { id: 'all', name: 'All News' },
  { id: 'tesla', name: 'Tesla' },
  { id: 'spacex', name: 'SpaceX' },
  { id: 'neuralink', name: 'Neuralink' },
  { id: 'x', name: 'X (Twitter)' },
  { id: 'boring', name: 'Boring Company' }
];

// Mock data - Replace with your actual data source
const mockNews = [
  {
    id: 1,
    title: 'Tesla Surpasses Production Milestone',
    excerpt: 'Tesla has announced record-breaking quarterly production numbers, exceeding market expectations.',
    category: 'tesla',
    date: '2024-03-15',
    readTime: '5 min read',
    image: '/api/placeholder/800/400'
  },
  // Add more mock news items...
];

const NewsCard = ({ article }) => (
  <motion.article 
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="bg-[#2e3244] rounded-xl overflow-hidden hover:shadow-xl transition-all duration-300"
  >
    <div className="relative aspect-video overflow-hidden">
      <img 
        src={article.image} 
        alt={article.title}
        className="w-full h-full object-cover transition-transform duration-300 hover:scale-105"
      />
    </div>
    <div className="p-6">
      <div className="flex items-center gap-4 mb-4">
        <span className="text-sm text-[#3384ff]">{article.category}</span>
        <div className="flex items-center text-white/50 text-sm">
          <Calendar className="w-4 h-4 mr-1" />
          {new Date(article.date).toLocaleDateString()}
        </div>
      </div>
      <h2 className="text-xl font-bold text-white mb-2">{article.title}</h2>
      <p className="text-white/70 mb-4">{article.excerpt}</p>
      <div className="flex items-center justify-between">
        <span className="text-sm text-white/50">{article.readTime}</span>
        <button className="text-[#3384ff] hover:text-[#3384ff]/80 transition-colors inline-flex items-center gap-2">
          Read More <ArrowRight className="w-4 h-4" />
        </button>
      </div>
    </div>
  </motion.article>
);

const NewsPage = () => {
  const [activeCategory, setActiveCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredNews, setFilteredNews] = useState(mockNews);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    // Filter news based on category and search term
    const filtered = mockNews.filter(article => {
      const matchesCategory = activeCategory === 'all' || article.category === activeCategory;
      const matchesSearch = article.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          article.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
      return matchesCategory && matchesSearch;
    });
    setFilteredNews(filtered);
    setIsLoading(false);
  }, [activeCategory, searchTerm]);

  return (
    <div className="min-h-screen bg-[#1f2333]">
      <Helmet>
        <title>Latest News | The Elon Musk Podcast</title>
        <meta name="description" content="Stay updated with the latest news about Tesla, SpaceX, Neuralink, and more." />
      </Helmet>

      <div className="max-w-7xl mx-auto px-6 py-12">
        {/* Header */}
        <div className="mb-12">
          <h1 className="text-4xl font-bold text-white mb-4">Latest News</h1>
          <p className="text-white/70">Stay updated with the latest developments across all companies</p>
        </div>

        {/* Search and Filter */}
        <div className="mb-8 space-y-6">
          <div className="relative">
            <Search className="absolute left-4 top-1/2 transform -translate-y-1/2 text-white/50" />
            <input
              type="text"
              placeholder="Search news..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full bg-[#2e3244] text-white pl-12 pr-4 py-3 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#3384ff]"
            />
          </div>

          <div className="flex flex-wrap gap-2">
            {categories.map(category => (
              <button
                key={category.id}
                onClick={() => setActiveCategory(category.id)}
                className={`px-4 py-2 rounded-full text-sm font-medium transition-colors ${
                  activeCategory === category.id
                    ? 'bg-[#3384ff] text-white'
                    : 'bg-[#2e3244] text-white/70 hover:bg-[#3384ff]/20'
                }`}
              >
                {category.name}
              </button>
            ))}
          </div>
        </div>

        {/* News Grid */}
        {isLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[1, 2, 3].map(i => (
              <div key={i} className="animate-pulse">
                <div className="bg-[#2e3244] rounded-xl">
                  <div className="aspect-video bg-[#1f2333]" />
                  <div className="p-6 space-y-4">
                    <div className="h-4 bg-[#1f2333] rounded w-1/4" />
                    <div className="h-8 bg-[#1f2333] rounded" />
                    <div className="h-4 bg-[#1f2333] rounded w-3/4" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            {filteredNews.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                {filteredNews.map(article => (
                  <NewsCard key={article.id} article={article} />
                ))}
              </div>
            ) : (
              <div className="text-center py-12">
                <p className="text-white/70">No news articles found matching your criteria.</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default NewsPage;