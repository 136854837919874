// src/services/episodeService.js

const ITEMS_PER_PAGE = 10;

export const fetchEpisodes = async (config, page = 1) => {
  try {
    const offset = (page - 1) * ITEMS_PER_PAGE;
    
    const response = await fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=${encodeURIComponent(config.feedUrl)}&api_key=${config.rss2jsonApiKey}&count=${ITEMS_PER_PAGE}&offset=${offset}`
    );
    
    const data = await response.json();
    
    if (!response.ok) {
      throw new Error('Failed to fetch episodes');
    }

    // Get total count from RSS feed (only on first page)
    let totalItems = 0;
    if (page === 1) {
      const feedResponse = await fetch(config.feedUrl);
      const feedText = await feedResponse.text();
      const parser = new DOMParser();
      const feedDoc = parser.parseFromString(feedText, "text/xml");
      totalItems = feedDoc.getElementsByTagName('item').length;
    }

    const processedEpisodes = data.items.map((item, index) => {
      // Extract categories
      const text = `${item.title} ${item.description}`.toLowerCase();
      const categories = [];
      
      if (text.includes('tech')) categories.push('Technology');
      if (text.includes('business')) categories.push('Business');
      if (text.includes('interview')) categories.push('Interview');
      // Add more category detection as needed

      return {
        id: totalItems - (offset + index),
        title: item.title,
        description: item.description,
        pubDate: item.pubDate,
        image: item.thumbnail || config.defaultImage,
        audioUrl: item.enclosure?.link,
        duration: item.duration || "00:00",
        episodeNumber: totalItems - (offset + index),
        categories: categories
      };
    });

    return {
      episodes: processedEpisodes,
      totalItems: totalItems,
      hasMore: offset + ITEMS_PER_PAGE < totalItems
    };
  } catch (error) {
    console.error('Error fetching episodes:', error);
    throw error;
  }
};

export const getUniqueCategories = (episodes) => {
  const categories = new Set();
  episodes.forEach(episode => {
    episode.categories.forEach(category => categories.add(category));
  });
  return Array.from(categories).sort();
};