import { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';

// Layout and Error Boundary
import MainLayout from './components/Layout/MainLayout';
import ErrorBoundary from './components/ErrorBoundary';

// Page Components
import Home from './pages/Home';
import Episodes from './pages/Episodes';
import EpisodeDetail from './pages/EpisodeDetail';
import Saved from './pages/Saved';
import About from './pages/About';
import TopicPage from './pages/TopicPage';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import Cookies from './pages/Cookies';
import NewsPage from './pages/News/NewsPage';

// Analytics Configuration
const MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID;
const ENABLE_ANALYTICS = import.meta.env.VITE_ENABLE_ANALYTICS === 'true';

// Analytics Wrapper Component
const AnalyticsWrapper = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    if (ENABLE_ANALYTICS) {
      // Initialize GA4
      ReactGA.initialize(MEASUREMENT_ID);
      
      // Log initial pageview
      ReactGA.send({
        hitType: "pageview",
        page: window.location.pathname + window.location.search
      });
    }
  }, []);

  useEffect(() => {
    if (ENABLE_ANALYTICS) {
      // Log pageview on route change
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname + location.search
      });
    }
  }, [location]);

  // Error tracking
  useEffect(() => {
    const handleError = (error) => {
      if (ENABLE_ANALYTICS) {
        ReactGA.event({
          category: 'Error',
          action: error.message,
          label: error.stack
        });
      }
    };

    window.addEventListener('error', handleError);
    return () => window.removeEventListener('error', handleError);
  }, []);

  return children;
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ErrorBoundary>
          <AnalyticsWrapper>
            <MainLayout>
              <Routes>
                {/* Main Routes */}
                <Route path="/" element={<Home />} />
                <Route path="/episodes" element={<Episodes />} />
                <Route path="/episode/:episodeId" element={<EpisodeDetail />} />
                <Route path="/saved" element={<Saved />} />
                <Route path="/about" element={<About />} />
                
                {/* Topic and News Routes */}
                <Route path="/topics/:topicSlug" element={<TopicPage />} />
                <Route path="/news" element={<NewsPage />} />
                
                {/* Legal Routes */}
                <Route path="/terms" element={<Terms />} />
                <Route path="/privacy" element={<Privacy />} />
                <Route path="/cookies" element={<Cookies />} />

                {/* 404 Route - Add your NotFound component */}
                <Route path="*" element={
                  <div className="min-h-screen flex items-center justify-center">
                    <div className="text-center">
                      <h1 className="text-4xl font-bold text-white mb-4">404 - Page Not Found</h1>
                      <p className="text-gray-400">The page you're looking for doesn't exist.</p>
                    </div>
                  </div>
                } />
              </Routes>
            </MainLayout>
          </AnalyticsWrapper>
        </ErrorBoundary>
      </Router>
    </HelmetProvider>
  );
}

export default App;