import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Mic, 
  Mail, 
  MapPin, 
  Facebook, 
  Twitter, 
  Instagram, 
  Linkedin,
  Radio,
  ExternalLink
} from 'lucide-react';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  const sections = {
    about: [
      { label: 'About Us', path: '/about' },
      { label: 'Our Team', path: '/about#team' },
      { label: 'Careers', path: '/careers' },
      { label: 'Contact', path: '/contact' }
    ],
    explore: [
      { label: 'All Episodes', path: '/episodes' },
      { label: 'Latest Episodes', path: '/episodes?sort=latest' },
      { label: 'Popular Episodes', path: '/episodes?sort=popular' },
      { label: 'Categories', path: '/categories' }
    ],
    resources: [
      { label: 'Help Center', path: '/help' },
      { label: 'Terms of Service', path: '/terms' },
      { label: 'Privacy Policy', path: '/privacy' },
      { label: 'Cookie Policy', path: '/cookies' }
    ],
    listening: [
      { label: 'Apple Podcasts', url: '#', external: true },
      { label: 'Spotify', url: '#', external: true },
      { label: 'Google Podcasts', url: '#', external: true },
      { label: 'RSS Feed', url: '#', external: true }
    ]
  };

  const socialLinks = [
    { icon: Facebook, url: '#', label: 'Facebook' },
    { icon: Twitter, url: '#', label: 'Twitter' },
    { icon: Instagram, url: '#', label: 'Instagram' },
    { icon: Linkedin, url: '#', label: 'LinkedIn' }
  ];

  return (
    <footer className="bg-[#2e3244] border-t border-white/10">
      <div className="max-w-7xl mx-auto px-6 py-12">
        {/* Main Footer Content */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-8 mb-12">
          {/* Brand Column */}
          <div className="lg:col-span-2">
            <Link to="/" className="flex items-center gap-2 mb-4">
              <Radio size={32} className="text-[#3384ff]" />
              <span className="text-2xl font-bold text-white">Elon Musk</span>
            </Link>
            <p className="text-white/70 mb-6 max-w-sm">
              Exploring the intersection of technology, innovation, and the future through in-depth conversations.
            </p>
            <div className="flex items-center gap-4">
              {socialLinks.map(({ icon: Icon, url, label }) => (
                <a
                  key={label}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="w-10 h-10 flex items-center justify-center rounded-lg bg-white/5 hover:bg-[#3384ff]/10 text-white/70 hover:text-[#3384ff] transition-colors"
                  aria-label={label}
                >
                  <Icon size={20} />
                </a>
              ))}
            </div>
          </div>

          {/* Navigation Columns */}
          {Object.entries(sections).map(([title, items]) => (
            <div key={title}>
              <h3 className="text-white font-semibold mb-4 capitalize">{title}</h3>
              <ul className="space-y-3">
                {items.map((item) => (
                  <li key={item.label}>
                    {item.external ? (
                      <a
                        href={item.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-white/70 hover:text-[#3384ff] transition-colors flex items-center gap-1"
                      >
                        {item.label}
                        <ExternalLink size={12} />
                      </a>
                    ) : (
                      <Link
                        to={item.path}
                        className="text-white/70 hover:text-[#3384ff] transition-colors"
                      >
                        {item.label}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>

        {/* Contact Information */}
        <div className="border-t border-white/10 pt-8 mb-8">
          <div className="flex flex-col md:flex-row gap-4 justify-between items-start md:items-center text-white/70">
            <div className="flex items-center gap-2">
              <MapPin size={16} />
              <span>100 Bull Street, Suite 200, Savannah, GA 34101</span>
            </div>
            <div className="flex items-center gap-2">
              <Mail size={16} />
              <a href="mailto:contact@elonmuskpodcast.com" className="hover:text-[#3384ff] transition-colors">
                contact@elonmuskpodcast.com
              </a>
            </div>
          </div>
        </div>

        {/* Copyright Bar */}
        <div className="border-t border-white/10 pt-8 flex flex-col md:flex-row justify-between items-center gap-4">
          <p className="text-white/50 text-sm">
            © {currentYear} Elon Musk Podcast. All rights reserved.
          </p>
          <div className="flex items-center gap-6">
            <Link to="/terms" className="text-white/50 hover:text-[#3384ff] text-sm transition-colors">
              Terms
            </Link>
            <Link to="/privacy" className="text-white/50 hover:text-[#3384ff] text-sm transition-colors">
              Privacy
            </Link>
            <Link to="/cookies" className="text-white/50 hover:text-[#3384ff] text-sm transition-colors">
              Cookies
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;