import React, { useState } from 'react';
import { Mail, Loader2, CheckCircle, XCircle } from 'lucide-react';

const NewsletterSubscribe = ({ className = '', darkMode = true }) => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('idle'); // idle, loading, success, error
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus('loading');

    try {
      // Replace these values with your Mailchimp endpoint details
      const MAILCHIMP_URL = process.env.REACT_APP_MAILCHIMP_URL;
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        setStatus('success');
        setMessage('Thanks for subscribing! Please check your email to confirm.');
        setEmail('');
      } else {
        throw new Error(data.message || 'Failed to subscribe');
      }
    } catch (error) {
      setStatus('error');
      setMessage(
        error.message === 'Member Exists' 
          ? "You're already subscribed to our newsletter!"  // Fixed quote here
          : 'Sorry, there was an error. Please try again.'
      );
    }
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="flex-1 relative">
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className={`w-full px-6 py-4 rounded-xl focus:outline-none focus:ring-2 
                ${darkMode 
                  ? 'bg-zinc-800 text-white placeholder-zinc-500 focus:ring-[#3384ff]' 
                  : 'bg-white text-gray-900 placeholder-gray-500 focus:ring-blue-500'
                }`}
              required
            />
            <Mail className={`absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 
              ${darkMode ? 'text-zinc-500' : 'text-gray-400'}`} 
            />
          </div>
          <button
            type="submit"
            disabled={status === 'loading'}
            className={`px-8 py-4 rounded-xl font-medium flex items-center justify-center gap-2 
              ${status === 'loading' ? 'opacity-70 cursor-not-allowed' : ''}
              ${darkMode 
                ? 'bg-[#3384ff] text-white hover:bg-[#3384ff]/90' 
                : 'bg-blue-600 text-white hover:bg-blue-700'
              } transition-colors`}
          >
            {status === 'loading' ? (
              <Loader2 className="w-5 h-5 animate-spin" />
            ) : (
              'Subscribe'
            )}
          </button>
        </div>

        {status !== 'idle' && (
          <div className={`flex items-center gap-2 text-sm
            ${status === 'success' 
              ? 'text-green-500' 
              : status === 'error' 
                ? 'text-red-500' 
                : 'text-gray-500'
            }`}
          >
            {status === 'success' ? (
              <CheckCircle className="w-5 h-5" />
            ) : status === 'error' ? (
              <XCircle className="w-5 h-5" />
            ) : null}
            {message}
          </div>
        )}
      </form>
    </div>
  );
};

export default NewsletterSubscribe;