import React, { useState, useEffect } from 'react';
import Marquee from 'react-fast-marquee';
import { ExternalLink } from 'lucide-react';

const headlines = [
  {
    id: 1,
    category: 'Tesla',
    text: 'Tesla achieves record-breaking quarterly deliveries with over 500,000 vehicles',
    link: '/news/tesla-deliveries'
  },
  {
    id: 2,
    category: 'SpaceX',
    text: 'Starship completes successful orbital flight test - major milestone for Mars mission',
    link: '/news/starship-test'
  },
  {
    id: 3,
    category: 'Neuralink',
    text: 'Neuralink receives FDA approval for expanded human trials',
    link: '/news/neuralink-trials'
  },
  {
    id: 4,
    category: 'Tesla',
    text: 'Cybertruck production ramps up at Giga Texas - First customer deliveries begin',
    link: '/news/cybertruck-production'
  },
  {
    id: 5,
    category: 'SpaceX',
    text: 'Starlink surpasses 2 million active subscribers globally',
    link: '/news/starlink-milestone'
  },
  {
    id: 6,
    category: 'X',
    text: 'X introduces new verified organization features and payment systems',
    link: '/news/x-updates'
  }
];

const getCategoryColor = (category) => {
  const colors = {
    Tesla: 'text-[#e82127]',    // Tesla Red
    SpaceX: 'text-[#005288]',   // SpaceX Blue
    Neuralink: 'text-[#8300ff]', // Neuralink Purple
    X: 'text-[#1DA1F2]'         // Twitter Blue
  };
  return colors[category] || 'text-white';
};

const NewsMarquee = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <div 
      className="bg-[#2e3244] border-b border-white/10"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="max-w-7xl mx-auto">
        <Marquee
          gradient={false}
          speed={40}
          pauseOnHover={true}
          pauseOnClick={true}
        >
          <div className="flex py-3">
            {headlines.map((headline) => (
              <a
                key={headline.id}
                href={headline.link}
                className="flex items-center mx-8 group"
              >
                <span className={`${getCategoryColor(headline.category)} font-semibold mr-2`}>
                  {headline.category}:
                </span>
                <span className="text-white/70 group-hover:text-white transition-colors">
                  {headline.text}
                </span>
                <ExternalLink className="w-4 h-4 ml-2 text-white/50 group-hover:text-white transition-colors" />
              </a>
            ))}
          </div>
        </Marquee>
      </div>
    </div>
  );
};

export default NewsMarquee;