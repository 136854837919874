// src/components/Layout/MainLayout.jsx
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Footer from '../Footer';
import { 
  Home,
  Mic,
  Bookmark,
  Info,
  Menu,
  ChevronLeft,
  Search,
  Bell,
  Settings,
  Rss,
  TrendingUp,
  Calendar,
  ExternalLink,
  Mail,
  Radio
} from 'lucide-react';

const theme = {
  background: '#1f2333',
  primary: '#2e3244',
  text: '#fff',
  accent: '#3384ff'
};

const SidebarLink = ({ to, icon: Icon, text, isActive, isCollapsed }) => (
  <Link
    to={to}
    className={`flex items-center gap-4 px-4 py-3 rounded-lg transition-all duration-200 group
      ${isActive ? 'bg-[#3384ff]/10 text-[#3384ff]' : 'text-white/70 hover:bg-[#2e3244]'}`}
  >
    <Icon 
      size={24} 
      className={`shrink-0 ${isActive ? 'text-[#3384ff]' : 'text-white/70 group-hover:text-white'}`}
    />
    {!isCollapsed && (
      <span className="font-medium group-hover:text-white">{text}</span>
    )}
  </Link>
);

const Advertisement = () => (
  <section className="bg-[#2e3244] rounded-xl p-6">
    <div className="flex items-center justify-between mb-4">
      <h3 className="text-sm font-medium text-white/70">Sponsored</h3>
      <span className="text-xs text-white/50">Ad</span>
    </div>
    <div className="aspect-video bg-[#1f2333] rounded-lg flex items-center justify-center mb-4">
      <span className="text-white/50">Advertisement Space</span>
    </div>
    <a 
      href="#" 
      className="text-xs text-white/70 hover:text-[#3384ff] transition-colors flex items-center gap-1"
    >
      Learn more <ExternalLink size={12} />
    </a>
  </section>
);

const NewsFeed = () => (
  <section className="bg-[#2e3244] rounded-xl p-6">
    <h3 className="text-sm font-medium text-white/70 mb-4 flex items-center gap-2">
      <Rss size={16} />
      Latest Updates
    </h3>
    <div className="space-y-4">
      {[
        { type: 'New Episode', title: 'The Future of Technology', time: '2 hours ago' },
        { type: 'Live Show', title: 'Upcoming Guest Interview', time: '1 day ago' },
        { type: 'News', title: 'Season 3 Announcement', time: '2 days ago' }
      ].map((item, i) => (
        <div key={i} className="border-b border-white/10 last:border-0 pb-4 last:pb-0">
          <span className="text-xs text-[#3384ff]">{item.type}</span>
          <h4 className="text-sm text-white font-medium hover:text-[#3384ff] transition-colors cursor-pointer">
            {item.title}
          </h4>
          <p className="text-xs text-white/50 mt-1">{item.time}</p>
        </div>
      ))}
    </div>
  </section>
);

const TrendingTopics = () => (
  <section className="bg-[#2e3244] rounded-xl p-6">
    <h3 className="text-sm font-medium text-white/70 mb-4 flex items-center gap-2">
      <TrendingUp size={16} />
      Popular Categories
    </h3>
    <div className="space-y-2">
      {[
        { name: 'Technology', count: 156 },
        { name: 'Business', count: 124 },
        { name: 'Science', count: 98 },
        { name: 'Future', count: 87 }
      ].map((category) => (
        <Link
          key={category.name}
          to={`/category/${category.name.toLowerCase()}`}
          className="flex items-center justify-between px-3 py-2 bg-[#1f2333] rounded-lg text-sm text-white/70 hover:bg-[#3384ff]/10 transition-colors group"
        >
          <span className="group-hover:text-[#3384ff] transition-colors">{category.name}</span>
          <span className="text-white/50">{category.count}</span>
        </Link>
      ))}
    </div>
  </section>
);

const UpcomingEpisodes = () => {
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <section className="bg-[#2e3244] rounded-xl p-6">
      <h3 className="text-sm font-medium text-white/70 mb-4 flex items-center gap-2">
        <Calendar size={16} />
        Coming Soon
      </h3>
      <div className="space-y-4">
        {[
          {
            date: new Date(Date.now() + 86400000),
            title: 'AI in Modern Business',
            time: '2:00 PM EST'
          },
          {
            date: new Date(Date.now() + 172800000),
            title: 'Tech Leaders Interview',
            time: '3:00 PM EST'
          }
        ].map((episode, i) => (
          <div key={i} className="flex gap-4 items-center group cursor-pointer">
            <div className="w-12 h-12 bg-[#1f2333] rounded-lg flex items-center justify-center group-hover:bg-[#3384ff]/10 transition-colors">
              <span className="text-sm text-white/70 group-hover:text-[#3384ff] transition-colors">
                {formatDate(episode.date)}
              </span>
            </div>
            <div>
              <h4 className="text-sm text-white font-medium group-hover:text-[#3384ff] transition-colors">
                {episode.title}
              </h4>
              <p className="text-xs text-white/50">{episode.time}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const LiveIndicator = () => (
  <section className="bg-[#2e3244] rounded-xl p-6">
    <div className="flex items-center gap-3">
      <div className="flex items-center gap-2">
        <span className="w-2 h-2 bg-red-500 rounded-full animate-pulse" />
        <span className="text-red-500 text-sm font-medium">LIVE</span>
      </div>
      <span className="text-white/70 text-sm">Currently Broadcasting</span>
    </div>
  </section>
);

const Newsletter = () => (
  <section className="bg-gradient-to-br from-[#3384ff]/10 to-[#3384ff]/5 rounded-xl p-6">
    <h3 className="text-sm font-medium text-white mb-2">Stay Updated</h3>
    <p className="text-xs text-white/70 mb-4">Get notified about new episodes</p>
    <div className="space-y-3">
      <input
        type="email"
        placeholder="Enter your email"
        className="w-full bg-[#1f2333] border border-white/10 rounded-lg px-4 py-2 text-sm text-white placeholder-white/30 focus:outline-none focus:ring-2 focus:ring-[#3384ff]"
      />
      <button className="w-full bg-[#3384ff] text-white rounded-lg px-4 py-2 text-sm hover:bg-[#3384ff]/90 transition-colors flex items-center justify-center gap-2">
        <Mail size={14} />
        Subscribe
      </button>
    </div>
  </section>
);

const RightSidebar = () => (
  <div className="w-80 flex-shrink-0 hidden xl:block">
    <div className="sticky top-4 space-y-6">
      <LiveIndicator />
      <Advertisement />
      <NewsFeed />
      <TrendingTopics />
      <UpcomingEpisodes />
      <Newsletter />
    </div>
  </div>
);

const MainLayout = ({ children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();

  const navigationItems = [
    { to: '/', icon: Home, text: 'Home' },
    { to: '/episodes', icon: Mic, text: 'Episodes' },
    { to: '/saved', icon: Bookmark, text: 'Saved' },
    { to: '/about', icon: Info, text: 'About' },
  ];

  return (
    <div className="min-h-screen bg-[#1f2333] text-white">
      <aside 
        className={`fixed left-0 top-0 h-screen bg-[#2e3244] border-r border-white/10 transition-all duration-300 z-50
          ${isCollapsed ? 'w-20' : 'w-64'}`}
      >
        <div className="flex flex-col h-full">
          <div className="h-20 flex items-center justify-between px-6 border-b border-white/10">
            {!isCollapsed && (
              <Link to="/" className="flex flex-col items-start">
                <div className="flex items-center gap-2">
                  <div className="flex flex-col">
                    <span className="text-xl font-bold tracking-wider text-[#3384ff] leading-none" style={{
                      textShadow: '0 0 10px rgba(51, 132, 255, 0.3)',
                      letterSpacing: '0.05em'
                    }}>
                      ELON MUSK
                    </span>
                    <span className="text-sm text-white/70 uppercase tracking-[0.2em] font-medium" style={{
                      transform: 'scale(0.9)',
                      transformOrigin: 'left'
                    }}>
                      podcast
                    </span>
                  </div>
                </div>
              </Link>
            )}
            <button
              onClick={() => setIsCollapsed(!isCollapsed)}
              className="p-2 hover:bg-white/5 rounded-lg transition-colors"
            >
              {isCollapsed ? (
                <Menu size={20} className="text-white/70" />
              ) : (
                <ChevronLeft size={20} className="text-white/70" />
              )}
            </button>
          </div>

          <nav className="flex-1 px-4 py-8 space-y-2 overflow-y-auto">
            {navigationItems.map((item) => (
              <SidebarLink
                key={item.to}
                {...item}
                isActive={location.pathname === item.to}
                isCollapsed={isCollapsed}
              />
            ))}
          </nav>

          {/* <div className="p-4 border-t border-white/10">
            <button 
              className="w-full flex items-center gap-4 px-4 py-3 rounded-lg text-white/70 hover:bg-white/5 transition-colors"
            >
              <Settings size={24} />
              {!isCollapsed && <span>Settings</span>}
            </button>
          </div> */}
        </div>
      </aside>

      <main 
        className={`transition-all duration-300 min-h-screen bg-[#1f2333] ${isCollapsed ? 'ml-20' : 'ml-64'}`}
      >
        <div className="w-full bg-[#1f2333]">
          <div className="flex gap-8 px-6">
            <div className="flex-1 py-6 bg-[#1f2333]">
              {children}
            </div>
            <RightSidebar />
          </div>
        </div>
        <Footer />
      </main>
    </div>
  );
};

export default MainLayout;